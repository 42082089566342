import React, { useEffect } from 'react'
import Hero from '../components/Hero/Hero'
import TitleSectionBadge from '../components/TitleSectionBadge'
import IncludeServiceCard from '../components/IncludeServiceCard'
import GridMockups from '../components/GridMockups'
import PlanCard from '../components/PlanCard'
export default function DesarrolloWeb() {


    const tiposWeb = [
        {
            title: "Desarrollo de Páginas web",
            description: "Desde un sitio web sencillo informativo hasta un sitio web complejo con funciones avanzadas, animaciones, gestor de contenidos etc. Pregunta por las diferentes opciones que tenemos para ti.",
            image: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Paginas-web-card-hero.png?alt=media&token=b1fb340f-cbd4-4216-a4b7-7f713c76bb0f"
        },
        {
            title: "Creación de Tiendas en línea",
            description: "Desarrollo de apps para tiendas online, apps seguras, procesadores de pagos, altamente escalables y con la mejor experiencia de usuario.",
            image: "https://images.unsplash.com/photo-1605902711834-8b11c3e3ef2f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80"
        },
        {
            title: "Sistemas y aplicaciones web",
            description: "¿Necesitas algo más complejo? Desde una plataforma para gestionar tu negocio, hasta apps web para tus clientes, nosotros te ayudamos a crear la solución que necesitas.",
            image: "https://images.unsplash.com/photo-1643513208375-df314b16347a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Hero
                title="Diseño y Desarrollo Web"
                description="Desarrollo web profesional, rápido y moderno. Implementamos la tecnología más avanzada para lograr los objetivos de tu proyecto"
                leftImage="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Hero-image-web-development-v2.webp?alt=media&token=9e927044-d5e7-43db-a52e-cca80e7b3b14"
                buttonText="Solicitar Cotización"
                buttonLinkTo="/cotiza"
            />

            <TitleSectionBadge description="Todos nuestros planes incluyen"></TitleSectionBadge>

            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">

                    <div className="col my-3">
                        <IncludeServiceCard classIconService="desarrollo-web-icon"
                            title="Diseño y Desarrollo Web"
                            description="Desde el bocetaje, mapeo y estrategia de comunicación hasta la programación y arquitectura web utilizamos las mejores herramientas y las más adecuadas para cada cliente, desde lo más básico hasta lo más avanzado y complejo.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="cloud-icon"
                            title="Hospedaje Google Cloud"
                            description="Hospedamos tu sitio por un año en la plataforma más segura, rápida y extensa del planeta.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="speed-icon"
                            title="Código optimizado"
                            description="Usamos código optimizado para hacer que el sitio cargue lo más rápido posible.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="phone-icon"
                            title="Diseño responsivo"
                            description="El diseño del sitio web responde y se adapta a los dispositivos móviles en el que se está mostrando.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="ssl-icon"
                            title="Certificado de seguridad"
                            description="Ofrece seguridad a los visitantes de tu sitio con un certificado de seguridad SSL.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="imagestock-icon"
                            title="Imágenes de stock"
                            description="Podemos proveer imágenes profesionales para ayudar a la imagen de su sitio.">
                        </IncludeServiceCard>
                    </div>
                </div>
            </div>


            <div className="my-5">
                <h1 className="text-dark title-brands">Servicios de<span className='text-purple-mexican'> Diseño y Desarrollo </span>Web</h1>
            </div>


            <div className="container">
                <div className="row row-cols-3">

                    {
                        tiposWeb.map((app, index) => (
                            <div className="col-12 col-md-4" key={index}>
                                <div className="card-box">
                                    <div className="card">
                                        <div className="card-front p-2"
                                            style={{
                                                backgroundSize: "cover",
                                                backgroundImage: `linear-gradient(153deg, rgba(28, 28, 28, 0.485) 0%, rgba(30, 0, 18, 0.542) 100%),url(${(app.image)})`
                                            }}>
                                            <div className="d-flex text-center">
                                                <h1 className='fs-2 text-light fw-bold'>{app.title}</h1>
                                            </div>
                                        </div>
                                        <div className="card-back p-3">
                                            <div className="d-flex">
                                                <p className='fs-4 text-light'>{app.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="my-5">
                <h1 className="text-dark title-brands">Conoce la  <span className='text-purple-mexican'>calidad </span> de nuestro trabajo</h1>
            </div>
            <GridMockups showFullWork={false}></GridMockups>



            <div class="container d-flex justify-content-center flex-column my-5">

                <h1 className="text-dark title-brands">Inicia tu proyecto con <span className='text-purple-mexican'>Desarrollalab</span></h1>
                <div className='text-center my-3'>
                    <span class="caption-text-purple"> <span class="caption-text text-dark my-auto">Cotiza tu proyecto ahora, desarrollamos </span>
                        proyectos éxitosos para empresas de todo el mundo.
                    </span>
                    <div className='my-4'>
                        <a href="/cotiza" class="btn btn-more-info">Contáctanos ➤➤</a>
                    </div>
                </div>

            </div>
        </div>
    )
}
