import React from 'react'
import '../assets/Index.css'
import ServiceCard from './ServiceCard'
export default function Services() {

    const services = [
        {
            titleService: "Diseño y Desarrollo Web",
            imgService: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Hero-image-web-development-v2.webp?alt=media&token=9e927044-d5e7-43db-a52e-cca80e7b3b14",
            subservices:
                [
                    "Creación de páginas web",
                    "Tiendas en línea | E-commerce",
                    "Aplicaciones web",
                    "Actualización y rediseño de sitios web",
                ],
            link: "/desarrollo-web"
        },
        {
            titleService: "Apps Móviles",
            imgService: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Hero-image-apps-moviles-v5.webp?alt=media&token=d965d462-040c-49a8-9ddd-20b4a5fd4370",
            subservices:
                [
                    "Desarrollo de aplicaciones web",
                    "Plataformas de mercado",
                    "Flutter y React Native",
                    "Desarrollo nativo en iOS y Android",
                    "Actualización y mantenimiento de aplicaciones",
                    "Diseño de experiencia de usuario (UX/UI)",
                ],
            link: "/aplicaciones-moviles"
        },
        {
            titleService: "Desarrollo de Software",
            imgService: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Hero-image-software-development-v5.webp?alt=media&token=f14263ee-dc9c-48ad-b496-be9a892a3c45",
            subservices:
                [
                    "Desarrollo de software personalizado",
                    "Desarrollo de software para empresas",
                    "Soporte y mantenimiento de software",
                    "Desarrollo Full Stack",
                    "Consultoría",
                ],
            link: "/desarrollo-de-software"
        }
    ]


    return (
        <div class="container mb-5" >
            <div class="row row-cols-2">

                {services.map((service, index) => (
                    <div class="col-12 col-md-4 my-4" key={index}>
                        <ServiceCard
                            titleService={service.titleService}
                            imgService={service.imgService}
                            subservices={service.subservices}
                            link={service.link}
                        />
                    </div>
                ))}
            </div>
        </div >
    )
}
