import React from 'react'

export default function WaFloatButton() {
    return (
        <a href="https://api.whatsapp.com/send?phone=+527772590365&text=Hola, estoy interesado en el servicio de: " class="btn float"
            target="_blank">
            <i className="fa fa-whatsapp wp-button"></i>
        </a>
    )
}
