import React, { useRef, useState, useEffect } from "react";
import * as d3 from "d3";

function WorldGlobe({ options }) {
    const svgRef = useRef(null);
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const width = options.width;
        const height = options.height;
        svg.attr("viewBox", `0 0 ${width} ${height}`)

        //create the globe
        const globe = svg
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`)
            .append("image")
            .attr("xlink:href", "https://desarrollalab.com/static/media/042_world_dots_2.a59f8171b1c8e5b5743d.svg")
            .attr("width", width)
            .attr("height", height);

        function animate() {
            setRotation(rotation => rotation + options.speed);
            globe.attr("transform", `rotate(${rotation})`);
            requestAnimationFrame(animate);
        }
        animate();
    }, [options]);

    return <svg ref={svgRef} width={options.width} height={options.height} />;
}

export default WorldGlobe;

