import React from 'react'

export default function IncludeServiceCard(props) {
    return (
        <div>
            <div className="container-include-serviceCard">
                <div className={props.classIconService}></div>
                <div>
                    <div className='my-2'>
                        <span className='includeTitle'>{props.title}</span>
                    </div>
                    <div className='container-includeDescription'>
                        <p className='includeDescription'>
                            {props.description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
