import React from 'react'
import Hero from '../components/Hero/Hero'
export default function AppsMoviles() {


    const tiposApps = [
        {
            title: "Apps a la medida",
            description: "Desarrollamos aplicaciones móviles para iOS y Android a la medida de tus necesidades, con las últimas tecnologías y metodologías de desarrollo.",
            image: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/heros%2FHero-image-apps-moviles.webp?alt=media&token=1571f6df-4518-404b-8135-772cb1216a97"
        },
        {
            title: "E-commerce",
            description: "Desarrollo de apps para tiendas online, apps seguras, prosesadores de pagos, altamente escalables y con la mejor experiencia de usuario.",
            image: "https://images.unsplash.com/photo-1605902711834-8b11c3e3ef2f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80"
        },
        {
            title: "Apps empresariales",
            description: "¿Necesitas una app para tu empresa o negocio? Desarrollamos apps que permiten llevar el funcionamiento de cualquier area de tu empresa a tu smartphone.",
            image: "https://images.unsplash.com/photo-1643513208375-df314b16347a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
        }
    ]

    return (
        <div>
            <Hero
                title="Desarrollo de Aplicaciones Móviles"
                description="Desarrollamos aplicaciones móviles para Android e iOS, con las últimas tecnologías y metodologías de desarrollo."
                leftImage="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Hero-image-apps-moviles-v5.webp?alt=media&token=d965d462-040c-49a8-9ddd-20b4a5fd4370"
                buttonText="Solicitar Cotización"
                buttonLinkTo="/cotiza"
            />


            <div className="container my-4">
                <div className="text-center my-5">
                    <h3 className='fw-bold text-dark'>Desarrollamos tu aplicación con la mejor tecnología</h3>

                    <div className="container my-5">
                        <div className="row row-cols-2 d-flex flex-row align-items-center align-content-center">
                            <div className="col-12 col-md-6">
                                <div className="my-2">
                                    <h4 className='text-left text-dark fw-bold'>Desarrollo de apps nativas</h4>
                                    <p className='text-dark fs-12 text-left my-4'>
                                        Aplicaciones móviles nativas desarrolladas para sistemas operativos móviles específicos, como iOS o Android, y los usuarios pueden acceder a ellas desde sus respectivas tiendas.
                                    </p>
                                </div>
                                <div className="container">
                                    <div className="row row-cols-3">
                                        <div className="col-4 col-md-4 d-flex flex-column align-items-center">
                                            <div className="apple-icon"></div>
                                            <p className='fs-6 text-dark'>iOS</p>
                                        </div>
                                        <div className="col-4 col-md-4 d-flex flex-column align-items-center">
                                            <div className="android-icon"></div>
                                            <p className='fs-6 text-dark'>Android</p>
                                        </div>
                                        <div className="col-4 col-md-4 d-flex flex-column align-items-center">
                                            <div className="swift-icon"></div>
                                            <p className='fs-6 text-dark'>Swift</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="my-2">
                                    <div className='text-left'>
                                        <h4 className='text-dark fw-bold'>Desarrollo de apps multiplataforma</h4>
                                    </div>
                                    <p className='text-dark fs-12 text-left my-4'>
                                        Aplicaciones móviles multiplataforma tienen el proósito de funcionar en múltiples plataformas móviles como iOS, Android, etc. sin cambiar el código base.
                                    </p>
                                </div>
                                <div className="container">
                                    <div className="row row-cols-3">
                                        <div className="col-4 col-md-4 d-flex flex-column align-items-center">
                                            <div className="flutter-icon"></div>
                                            <p className='fs-6 text-dark'>Flutter</p>
                                        </div>
                                        <div className="col-4 col-md-4 d-flex flex-column align-items-center">
                                            <div className="react-icon"></div>
                                            <p className='fs-6 text-dark'>React Native</p>
                                        </div>
                                        <div className="col-4 col-md-4 d-flex flex-column align-items-center">
                                            <div className="ionic-icon"></div>
                                            <p className='fs-6 text-dark'>Ionic</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-cols-3">

                    {
                        tiposApps.map((app, index) => (
                            <div className="col-12 col-md-4">
                                <div className="card-box">
                                    <div className="card">
                                        <div className="card-front"
                                            style={{
                                                backgroundSize: 'cover',
                                                backgroundImage: `linear-gradient(153deg, rgba(28, 28, 28, 0.485) 0%, rgba(30, 0, 18, 0.542) 100%),url(${(app.image)})`
                                            }}>
                                            <div className="d-flex text-center">
                                                <h1 className='fs-2 text-light fw-bold'>{app.title}</h1>
                                            </div>
                                        </div>
                                        <div className="card-back p-3">
                                            <div className="d-flex">
                                                <p className='fs-4 text-light'>{app.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="my-5">
                    <div className="container">
                        <div className="row row-cols-2">
                            <div className="col-12 col-md-6 d-flex align-content-center flex-column align-items-center">
                                <div className="my-4">
                                    <div className="my-2">
                                        <h3 className="text-dark text-left title-brands">¿Por qué <span className='text-purple-mexican'>elegirnos?</span></h3>
                                    </div>
                                    <p className='text-dark fs-12'>
                                        Somos una empresa con alta experiencia en el desarrollo de aplicaciones móviles, con un equipo de profesionales altamente capacitados y con una metodología de trabajo que nos permite entregar proyectos de calidad en tiempo y forma.
                                    </p>
                                </div>
                                <div className="my-2">
                                    <p className='text-dark fs-12'>No importa el tipo de app, nuestros servicios de desarrollo de aplicaciones móviles superiores están diseñados para ofrecer soluciones de vanguardia orientadas a las necesidades de su negocio.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 d-flex align-content-center flex-column align-items-center">
                                <div className="why-jire-us"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-4">
                    <div className="container bg-light-gray p-2">
                        <div className="row row-cols-3">
                            <div className="col-12 col-md-4">
                                <div className="d-flex align-items-center">
                                    <div className="circle-list"></div>
                                    <span className='text-dark fw-bold fs-5'>
                                        Desarrollo de UX/UI
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="d-flex align-items-center">
                                    <div className="circle-list"></div>
                                    <span className='text-dark fw-bold fs-5'>
                                        Desarrollo Nativo
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="d-flex align-items-center">
                                    <div className="circle-list"></div>
                                    <span className='text-dark fw-bold fs-5'>
                                        Desarrollo Multiplataforma
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="d-flex align-items-center">
                                    <div className="circle-list"></div>
                                    <span className='text-dark fw-bold fs-5'>
                                        Soluciones Empresariales
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="d-flex align-items-center">
                                    <div className="circle-list"></div>
                                    <span className='text-dark fw-bold fs-5'>
                                        Desarrollo de Prototipos de Apps
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="d-flex align-items-center">
                                    <div className="circle-list"></div>
                                    <span className='text-dark fw-bold fs-5'>
                                        Mantenimiento y Soporte
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container d-flex justify-content-center flex-column my-5">

                    <h1 className="text-dark title-brands">Inicia tu proyecto con <span className='text-purple-mexican'>Desarrollalab</span></h1>
                    <div className='text-center my-3'>
                        <span class="caption-text-purple"> <span class="caption-text text-dark my-auto">Cotiza tu proyecto ahora, desarrollamos </span>
                            proyectos éxitosos para empresas de todo el mundo.
                        </span>
                        <div className='my-4'>
                            <a href="/cotiza" class="btn btn-more-info">Contáctanos ➤➤</a>
                        </div>
                    </div>

                </div>


            </div>
            <div className='container-fluid container-text-techbehemots'>
                <div className="container my-5">
                    <div className="row d-flex align-items-center">
                        <h1 className="text-light title-brands">Premiaciones <span className='text-purple-mexican'>destacadas</span></h1>
                        <div className='text-center'>
                            <span class="text-light fs-5">Top Software Company in México</span>
                        </div>
                    </div>
                    <div className="row row-cols-3">
                        <div className="col-12 my-3 col-md-4 d-flex justify-content-center">
                            <div className='award-custom-software'></div>
                        </div>
                        <div className="col-12 my-3  col-md-4 d-flex justify-content-center">
                            <div className='award-web-development'></div>
                        </div>
                        <div className="col-12 my-3 col-md-4 d-flex justify-content-center">
                            <div className='award-uxui'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
