import React, { useState, useEffect } from 'react'
import Hero from '../components/Hero'
import HeroMobile from '../components/HeroMobile'
import WaFloatButton from '../components/WaFloatButton'
import FormularioContacto from '../components/FormularioContacto'

export default function Contacto() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{ backgroundColor: "#F2F2FA" }}>
            <div id="pc">
                <Hero
                    heroImage={true}
                    important="Cuéntanos un poco sobre tu proyecto"
                    description="Utiliza nuestro cotizador rápido para aterrizar mejor tu proyecto"
                    classHeroPage="heroBackgroundGetInTouch">
                </Hero>
            </div>

            <div id="mb">
                <HeroMobile
                    important="Cuéntanos un poco sobre tu proyecto"
                    description="Utiliza nuestro cotizador rápido para aterrizar mejor tu proyecto"
                    classHeroPage="heroBackgroundGetInTouch">
                </HeroMobile>
            </div>
            <FormularioContacto></FormularioContacto>
        </div>
    )
}
