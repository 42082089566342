import React from 'react'
import { Link } from 'react-router-dom'
import CardMockup from '../components/CardMockup'
import FadeInLeft from '../animations/FadeInLeft'
export default function GridMockups(props) {

    const proyectos = [
        {
            nombreEmpresa: "PRO-WELDING",
            actividadEmpresa: "Distribuidor nacional Lincoln Electric",
            descripcionProyecto: "PRO-WELDING S.A. de C.V. al ser una empresa que representa una marca tan importante como lo es Lincoln solicitó a Desarrollalab la creación de un sitio web altamente profesional que proyectara la escencia y figura de Pro-welding, además de un amplio y completo catálogo de productos de la marca.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/mockups%2FPro-Welding%20Industrial%20Supplier.png?alt=media&token=734deed7-d678-46d6-a907-59a7d3fab382",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/mockups%2FPRO-WELDING-MC-1.webp?alt=media&token=0758eefb-891d-45b5-bf24-3d0f9af5b320",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/mockups%2FPRO-WELDING-MC-2.webp?alt=media&token=b6a91cb1-a9f5-4c62-8b54-d8ffa103fc65",
            ],
            design: {
                backgroundColor: "#272727",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "DERMAFARMA",
            actividadEmpresa: "Tienda en línea para Farmacia Dermatológica",
            descripcionProyecto: "Dermafarma necesitaba un aliado estratégido que le ayudara a alcanzar sus objetivos de crecimiento de su E-commerce para esto contando con los años de experiencia, calidad y compromiso de Desarrollalab, se trabajó de la mano con esta marca para superar los estándares de calidad para la venta de productos dermatológicos, proporcionando un diseño 100% profesional y amigable con los compradores.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/mockups%2FFAVICON%20DERMAFARMA%202.png?alt=media&token=3a8d415f-92ba-4cb8-ad7b-4600f7f694a3",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/mockups%2FDERMAFARMA-MC-1.webp?alt=media&token=77a48f93-9ac5-45db-9bec-3e2f4b6eb562",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/mockups%2FDERMAFARMA-MC-2.webp?alt=media&token=93483f81-0fe4-4a2f-8429-5315759c1b8e",
            ],
            design: {
                backgroundColor: "#EFEFEF",
                textColor: "#000"
            }
        },
        {
            nombreEmpresa: "OA ARQUITECTOS",
            actividadEmpresa: "Despacho de arquitectura integral",
            descripcionProyecto: "OA Arquitectos nos solicitó el diseño y desarrollo de su sitio web, por lo cual entregamos un sitio con diseño elegante y minimalista ya que la empresa se dedica a proyectos de alta gama. Además en el sitio se deben mostrar los proyectos de la empresa, para esto se desarrolló un CMS que les permite gestionar su sitio de manera fácil y rápida sin necesidad de conocimientos técnicos.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/IMAGOTIPO-OA-PNG.png?alt=media&token=a6638072-d492-4e69-95db-3c2f63312664",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/OA-ARQUITECTOS-MOCKUP.webp?alt=media&token=926b0508-2d57-41b3-a88f-e103d66cd8fc",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/OA-ARQUITECTOS-MOCKUP-3.webp?alt=media&token=a5ab1211-4e31-4e40-9061-aa5350ca56c9",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/OA%20ARQUITECTOS%20MOCKUP%20-2.jpg?alt=media&token=1c832b8d-a56c-48b3-ba0d-cc3ff17a6c0b"
            ],
            design: {
                backgroundColor: "#000000",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "CLPA",
            actividadEmpresa: "CC-LINK Partner Association América",
            descripcionProyecto: "Desarrollo de sitio web e integración de CMS para la gestión de contenidos, se da el soporte y mantenimiento del sitio web y constantemente se integran nuevas funcionalidades dentro del CMS. Hoy en día gracias al profesionalismo con la que hemos respondido, seguimos trabajando juntos.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Fbrand_003_clpa.png?alt=media&token=084331ae-2ccd-4d1d-88a5-3731cb6a2e31",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/CLPA-MOCKUP.webp?alt=media&token=06e99f05-c35d-4a28-9e5d-9e622fc2c0aa",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/CLPA%20-%20MOCKUP%20-2.jpg?alt=media&token=9285daeb-ec65-46f8-88b0-9d84d9cfe63e"
            ],
            design: {
                backgroundColor: "#0050B1",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "REPROVETHORSES",
            actividadEmpresa: "Aplicación líder en el mercado de la reproducción equina",
            descripcionProyecto: "Desarrollo de aplicación móvil para la gestión de la reproducción equina, cada usuario de la aplicación cuenta con un perfil que le permite gestionar la información y los datos de los caballos así como sus respectivos propietarios.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/logo%20REPROVETHORSE%20BLANCO%201.png?alt=media&token=476b2bb1-c387-4dbe-b2bf-8e6ca0617300",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Reprovethorses_3_11zon.webp?alt=media&token=aed5041a-c8b2-4f1b-b037-c5a773c46585",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Reprovethorses%20-2.jpg?alt=media&token=30008a48-73b3-4006-a507-e0c0a5a05689"
            ],
            design: {
                backgroundColor: "#B27BF6",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "IGNITE",
            actividadEmpresa: "Microsoft Gold Partner - Empresa Chilena Líderes reconocidos en el mercado de servicios de nube",
            descripcionProyecto: "El reto: Rediseño del sitio web, la página tenía que representar el privilegio de Ignite como Microsoft Gold Partner, además de mostrar la experiencia y el conocimiento de la empresa en el mercado de servicios de nube. Desarrollalab superó por mucho las expectativas y requerimientos de Ignite, por lo que hoy son clientes que nos recomiendan en todo Chile",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/ignite-logo-blanco.png?alt=media&token=5d4593a6-a053-49c3-b8f6-e0813c3dc21d",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Ignite-1.jpg?alt=media&token=bfd3bb36-62b2-40a8-8e2f-05acf0c3aefa",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Ignite%20-2.jpg?alt=media&token=dd60688f-301f-49e6-a9c2-d1dfee6ac421"
            ],
            design: {
                backgroundColor: "#1193DE",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "Finca La Esperanza",
            actividadEmpresa: "Empresa Mexicana dedicada a la producción de café gourmet",
            descripcionProyecto: "Finca La Esperanza nos solicitó el diseño y desarrollo de su sitio web que lograra plasmar la escencia de su marca, la pasión, la creatividad y el amor por la naturaleza y el trabajo que desempeñan. Además, desarrollamos una tienda en línea donde puedan vender su café de manera fácil y segura.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/012_logo-finca-blanco%201.png?alt=media&token=a70094f8-7361-42bf-aa5c-99894d625a74",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Finca-La-Esperanza_6_11zon.webp?alt=media&token=b74e6c9d-c8a3-4b72-9dea-b6416dbce3be",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Finca%20La%20Esperanza%20-2.jpg?alt=media&token=e754ce39-316e-4c41-a689-740d4fd529f1"
            ],
            design: {
                backgroundColor: "#79492C",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "Dra. Edith Leal Castañeda",
            actividadEmpresa: "Aesthetic & Antiaging Medicine",
            descripcionProyecto: "La Dra. Edith Leal Castañeda es una reconocida médica especialista en estética y antiaging, que nos solicitó el rediseño de su página web, misma que anteriormente también fue desarrollada por Desarrollalab, gracias a la calidad y profesionalismo que ofrecemos volvió a confiar en nuestra empresa. La Dra. Edith está completamente satisfecha con el trabajo que hemos realizado.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2F001-logo-draedithlealcastaneda-resized.png?alt=media&token=5ca54244-6565-4dbb-9ab2-cd922601b6ed",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Dra-edith-leal_2_11zon.webp?alt=media&token=34458be9-fed1-47cc-ac7b-662d4a7f9051",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Dra%20edith%20leal%20-2.jpg?alt=media&token=c97d1e30-c86f-4f17-b216-e15994240a1a"
            ],
            design: {
                backgroundColor: "#08A9A9",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "MyQRAlert",
            actividadEmpresa: "Plataforma de emergencia Médica, con perfiles digitales conectados placas integligentes",
            descripcionProyecto: "La empresa vende placas de identificación inteligente, que mediante un QR grabado en ellas, permite a los usuarios acceder al perfil digital del portador de la placa, el cual contiene información de contacto de emergencia, alergias, enfermedades crónicas, medicamentos, etc. Desarrollalab desarrolló la plataforma web de MyQRAlert, la cual permite a los usuarios crear sus perfiles digitales y a los médicos acceder a ellos en caso de emergencia.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&token=0743be12-56ea-4341-957c-f0501675355f",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Myqralert_4_11zon.webp?alt=media&token=f6fb0dd3-c4df-4c8a-ba0d-356ace581477",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Myqralert-2.webp?alt=media&token=7e0720a3-5e6f-4c2b-81f7-0bed36e1b8f3",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Myqralert-3.webp?alt=media&token=73907dd5-593f-4070-aed5-41f35fe532a2"
            ],
            design: {
                backgroundColor: "#F26716",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "IDPETSQR.COM",
            actividadEmpresa: "Plaquitas de identificación inteligente para mascotas",
            descripcionProyecto: "IDPETSQR.COM es una empresa que se dedica a la comercialización de placas de identificación inteligente para mascotas, las cuales permiten a los dueños de las mascotas, (y en caso de extravío, a cualquier persona que lea el tag) acceder a la información de contacto de emergencia, enviar en tiempo real la ubicación de donde se encontró a la mascota. IDPETSQR sigue trabajando con Desarrollalab para mejorar la plataforma web, con el respaldo de las mejores tecnologías.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2FLOGO-png.png?alt=media&token=baea4912-2b67-42cc-9ff2-61dff7497595",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/IDPETSQR-1.webp?alt=media&token=cb9b73c9-04c0-4665-93cd-4f49dcd451bf",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/IDPETSQR-2.webp?alt=media&token=20e0d732-cf42-451c-8de4-1f5666b0593b",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/IDPETSQR-3.webp?alt=media&token=a1afc456-ff5a-4664-bb16-0d62019841cb"
            ],
            design: {
                backgroundColor: "#FCBB31",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "FOS - Arquitectos",
            actividadEmpresa: "Despacho de arquitectura y diseño",
            descripcionProyecto: "FOS - Arquitectos es un despacho de arquitectura y diseño, que nos solicitó el diseño y desarrollo de su sitio web, el cual es moderno, elegante y sobrio, ya que la empresa se dedica a proyectos de alta gama. El sitio web debe ser fácil de navegar y debe mostrar los proyectos que ha realizado la empresa.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2F007_logo_fos.png?alt=media&token=1079b947-fc55-4a2e-9bdc-6efb06c305cd",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/FOS-ARQUITECTOS-1.webp?alt=media&token=e8c4f7d5-378b-4108-a283-c18dd8461fb3",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/FOS-ARQUITECTOS-2.webp?alt=media&token=cd3195f2-a025-4930-807b-5bef0d3f0a8a",
            ],
            design: {
                backgroundColor: "#626262",
                textColor: "#fff"
            }
        },
        {
            nombreEmpresa: "Comercializadora Darnoy - SAS de CV",
            actividadEmpresa: "E-commerce de venta de productos diversos",
            descripcionProyecto: "Comercializadora Darnoy es una empresa que se dedica a la comercialización de productos de cómputo, equipo de defensa personal, y gadgets militar.",
            logoEmpresa: "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2FLOGOTIPO-OFICIAL-DARNOY-SIN-LEYENDA-COLOR-AMARILLO.png?alt=media&token=fb59a87a-ea55-424f-b497-c558daee760a",
            images: [
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Darnoy-1.webp?alt=media&token=4e4701de-d5a0-43bd-82e6-e09aeca8c5b9",
                "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Darnoy-2.webp?alt=media&token=b822712c-df14-41d8-b16c-69593eaa2068",
            ],
            design: {
                backgroundColor: "#040527",
                textColor: "#fff"
            }
        }
    ]

    return (
        <div class="container-fluid p-0">

            {
                props.showFullWork == true ?

                    proyectos.map((proyecto, index) => {
                        return (
                            <CardMockup
                                key={index}
                                nombreEmpresa={proyecto.nombreEmpresa}
                                actividadEmpresa={proyecto.actividadEmpresa}
                                logoEmpresa={proyecto.logoEmpresa}
                                images={proyecto.images}
                                descripcionProyecto={proyecto.descripcionProyecto}
                                design={proyecto.design}
                            ></CardMockup>
                        )
                    })
                    :
                    <div class="container">
                        <div class="row rows-cols-2">
                            <div class="col col-mkp">
                                <img class="mockup" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/mockups%2FPRO-WELDING-MC-1.webp?alt=media&token=0758eefb-891d-45b5-bf24-3d0f9af5b320" alt="PRO-WELDING - DISTRIBUIDOR NACIONAL LINCOLN ELECTRIC"></img>
                            </div>
                            <div class="col col-mkp">
                                <img class="mockup" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/OA-ARQUITECTOS-MOCKUP.webp?alt=media&amp;token=926b0508-2d57-41b3-a88f-e103d66cd8fc" alt="OA ARQUITECTOS - DESPACHO DE ARQUITECTURA INTEGRAL"></img>
                            </div>
                            <div class="col col-mkp">
                                <img class="mockup" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/mockups%2FDERMAFARMA-MC-1.webp?alt=media&token=77a48f93-9ac5-45db-9bec-3e2f4b6eb562" alt="DERMAFARMA - TIENDA EN LÍNEA"></img>
                            </div>
                            <div class="col col-mkp">
                                <img class="mockup" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Reprovethorses_3_11zon.webp?alt=media&amp;token=aed5041a-c8b2-4f1b-b037-c5a773c46585" alt="App Reprovethorses"></img>
                            </div>
                        </div>
                    </div>

            }

            {
                props.showFullWork !== true ?
                    <div className="my-4 d-flex justify-content-center">
                        <Link to="/portafolio" className="btn btn-more-info" rel='noopener noreferrer'>Ver portafolio ➤➤</Link>
                    </div>
                    :
                    <div></div>
            }
        </div>
    )
}
