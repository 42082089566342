import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/Index.css'

export default function Hero(props) {

  return (
    <div id={props.classHeroPage}>
      <div className="bannerHero">
        <h1 className={`heroTitle title-brands-hero ${props.classText}`}>{props.important}</h1>
        <h1 className="heroTitle">{props.name}</h1>
        <div className="my-2">
          <span className="text-white text-start fs-5">{props.description}
          </span>
        </div>
        {
          props.showButton == true ?
            <Link className="btn btn-more-info m-2" style={{ borderRadius: '10px' }} to={props.to}>Conoce más ➤➤</Link>
            :
            ""
        }


        {
          window.location.pathname !== '/agendar-reunion' ?
            <Link className="btn btn-more-info m-2" style={{ borderRadius: '10px' }} to={"/agendar-reunion"}>Agendar una reunión ➤➤</Link>
            :
            null
        }

      </div>
    </div>
  )
}
