import React from 'react'
import Hero from '../components/Hero'
import HeroMobile from '../components/HeroMobile'
import WaFloatButton from '../components/WaFloatButton'
import TitleSectionBadge from '../components/TitleSectionBadge'
import IncludeServiceCard from '../components/IncludeServiceCard'
import GridMockups from '../components/GridMockups'
import PlanCard from '../components/PlanCard'
import Footer from '../components/Footer'
export default function Ecommerce() {

    return (
        <div>

            <div id="pc">
                <Hero
                    heroImage={true}
                    important="Creación de Tiendas en Línea"
                    description={"Desarrollo de tiendas en línea profesionales, rápidas y vendedoras. E-commerce profesional"}
                    classHeroPage="heroEcommerce">
                </Hero>
            </div>

            <div id="mb">
                <HeroMobile
                    heroImage={true}
                    important="Creación de Tiendas en Línea"
                    description={"Desarrollo de tiendas en línea profesionales, rápidas y vendedoras. Adaptables a cualquier dispositivo"}
                    classHeroPage="heroEcommerce">
                </HeroMobile>
            </div>

            <TitleSectionBadge description="Todos nuestros planes incluyen"></TitleSectionBadge>

            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">

                    <div className="col my-3">
                        <IncludeServiceCard classIconService="diseno-web-icon"
                            title="Diseño profesional"
                            description="Utilizamos las mejores herramientas y las más adecuadas para cada cliente, desde lo más básico hasta lo más avanzado y complejo.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="cloud-icon"
                            title="Hospedaje Google Cloud"
                            description="Hospedamos tu sitio por un año en la plataforma más segura, rápida y extensa del planeta.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="phone-icon"
                            title="Diseño responsivo"
                            description="El diseño del sitio web responde y se adapta a los dispositivos móviles en el que se está mostrando.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="ssl-icon"
                            title="Certificado de seguridad"
                            description="Ofrece seguridad a los visitantes de tu sitio con un certificado de seguridad SSL.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="payment-icon"
                            title="Pasarela de pagos"
                            description="Recibe pagos con tarjetas de débido o crédito de forma segura y rápida.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="cart-icon"
                            title="Carrito de Compras"
                            description="Carrito de compras integrado en tu Tienda en Línea para que tus clientes puedan seleccionar tus productos y comprarlos.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="inventory-icon"
                            title="Inventario"
                            description="Supervisa las ventas, promociones, comentarios, los niveles de existencias y el rendimiento general de tu tienda, todo desde un panel de control.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="check-icon"
                            title="Panel fácil de usar"
                            description="Administra fácilmente tus productos, precios, ofertas, cupones, inventario, usuarios, despacho, imágenes y más. La tienda crecerá con tu negocio.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="support-icon"
                            title="Soporte y capacitación"
                            description="Te enseñamos a administrar y actualizar el contenido de tu tienda fácilmente. Estaremos contigo en cada fase del proyecto.">
                        </IncludeServiceCard>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">

                    <div className="col my-3">
                        <PlanCard
                            planType="ecommerce"
                            planName="Básico"
                            workDays="Ideal para emprendimientos que buscan entrar al mundo digital y no saben por dónde empezar, este plan tiene todo lo necesario para tu emprendimiento"
                            planPrice="7,000"
                            discount="1500"
                        >
                        </PlanCard>
                    </div>
                    <div className="col my-3">
                        <PlanCard
                            planType="ecommerce"
                            planName="Prémium"
                            workDays="Perfecto para negocios establecidos o que ya venden físicamente y buscan amplicar su mercado vendiendo en internet"
                            planPrice="10,000"
                            discount="1500"
                        >
                        </PlanCard>
                    </div>
                    <div className="col my-3">
                        <PlanCard
                            planType="ecommerce"
                            planName="Empresarial"
                            workDays="Pensado para empresas que buscan expandir sus E-commerce a nivel nacional o internacional, este plan integra todo lo necesario para un E-commerce con capacidad de expansión"
                            planPrice="15,000"
                            discount="1500"
                        >
                        </PlanCard>
                    </div>
                </div>

            </div>
            <div className="d-flex justify-content-center my-4">
                <span className='no-iva'>Los precios no incluyen IVA</span>
            </div>
            <TitleSectionBadge title="Conoce la calidad de nuestro trabajo"></TitleSectionBadge>
            <GridMockups showFullWork={false}></GridMockups>
        </div>
    )
}
