import React, { useEffect } from 'react';

const ReviewsGoogle = () => {
    useEffect(() => {
        const removeSignatureLink = () => {
            const signatureLink = document.querySelector('[href*="elfsight.com"]');
            if (signatureLink) {
                signatureLink.remove();
                console.log('Signature link removed');
            }
        };

        // Initial attempt to remove the signature after 3 seconds
        const timeoutId = setTimeout(removeSignatureLink, 3000);

        // MutationObserver to monitor the DOM for any new additions of the signature link
        const observer = new MutationObserver(() => {
            removeSignatureLink();
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        // Cleanup on component unmount
        return () => {
            clearTimeout(timeoutId);
            observer.disconnect();
        };
    }, []);

    return (
        <div className="container my-4">
            <div className="elfsight-app-173c2ac6-ec36-40a6-b5ee-2099f11819c6" data-elfsight-app-lazy></div>
        </div>
    );
};

export default ReviewsGoogle;