import React from 'react'
import Hero from '../components/Hero'
import HeroMobile from '../components/HeroMobile'
import TitleSectionBadge from '../components/TitleSectionBadge'
import Footer from '../components/Footer'

export default function AppsWeb() {
    return (
        <div>

            <div id='pc'>
                <Hero
                    showButton={true} to={"/cotiza"}
                    important="Desarrollo de Aplicaciones Web y Plataformas"
                    description={"Un enfoque eficaz para modernizar el software antiguo y crear nuevo software personalizado. Desde la minimización del riesgo, el tiempo de comercialización, la mejora de la UX y la UI hasta las necesidades de actualización completa."}
                    classHeroPage="heroAppsWeb">
                </Hero>
            </div>

            <div id="mb">
                <HeroMobile
                    showButton={true} to={"/cotiza"}
                    important="Desarrollo de Aplicaciones Web y Plataformas"
                    description="Nadie mejor que tu conoce a tu empresa y sabes qué es lo que necesita para impulsar su crecimiento, nosotros te ayudaremos desarrollando soluciones que se ajusten a tus necesidades y optimicen las áreas estratégicas de tu empresa."
                    classHeroPage="heroAppsWeb">
                </HeroMobile>
            </div>


            <div className="container" id='pc'>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">
                    <div className="col">
                        <div className="container-text-techbehemots">
                            <TitleSectionBadge title="Ayudamos a nuestros clientes a diseñar, validar, construir y escalar software personalizado" description="Brindamos servicio a cientos de empresas líderes en múltiples industrias para construir sus aplicaciones de software indispensables para el negocio. 
                             Nuestros experimentados Ingenieros ágiles trabajan en proyectos de desarrollo de software complejos, full-stack, móviles, web y empresariales que impulsan el crecimiento y la innovación."></TitleSectionBadge>
                        </div>
                    </div>
                    <div className="col software-app-description-bg"></div>
                </div>
            </div>


            <div id='mb'>
                <div className="container-text-techbehemots">
                    <TitleSectionBadge title="Ayudamos a nuestros clientes a diseñar, validar, construir y escalar software personalizado" description="Brindamos servicio a cientos de empresas líderes en múltiples industrias para construir sus aplicaciones de software indispensables para el negocio. 
                             Nuestros experimentados Ingenieros ágiles trabajan en proyectos de desarrollo de software complejos, full-stack, móviles, web y empresariales que impulsan el crecimiento y la innovación."></TitleSectionBadge>
                </div>
                <div className="software-app-description-bg"></div>
            </div>


            {/* Capacidades */}
            <div>

                <TitleSectionBadge title="Capacidades" description="Nuestros servicios cubren todo el espectro de necesidades, desde la estrategia hasta la gestión de servicios, la transformación de la red y las soluciones para el lugar de trabajo, y más."></TitleSectionBadge>

                <div className="container">
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2'>
                        <div className="col my-2">
                            <div class="card-no-shadow">
                                <div class="card-body">

                                    <div className='badge-number my-2'>
                                        <span className='fs-6 text-light'>1</span>
                                    </div>
                                    <h5 class="card-title">Flexible</h5>
                                    <p class="card-text">Adaptación a los distintos procesos que forman parte de la solución, basados en estándares de desarrollo y patrones de diseño.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-2">
                            <div class="card-no-shadow">
                                <div class="card-body">
                                    <div className='badge-number my-2'>
                                        <span className='fs-6 text-light'>2</span>
                                    </div>
                                    <h5 class="card-title">Ágil</h5>
                                    <p class="card-text">Se buscará una integración continua con el cliente para evitar retrabajo.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-2">
                            <div class="card-no-shadow">
                                <div class="card-body">
                                    <div className='badge-number my-2'>
                                        <span className='fs-6 text-light'>3</span>
                                    </div>
                                    <h5 class="card-title">Seguro</h5>
                                    <p class="card-text">Se utilizarán estándares de seguridad basados en su negocio, pudiendo incluso aplicar los estándares bancarios más altos.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <div class="card-no-shadow">
                                <div class="card-body">
                                    <div className='badge-number my-2'>
                                        <span className='fs-6 text-light'>4</span>
                                    </div>
                                    <h5 class="card-title">Robusto</h5>
                                    <p class="card-text">Tolerante a fallas; y de alta disponibilidad asociado a la infraestructura desplegada.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <div class="card-no-shadow">
                                <div class="card-body">
                                    <div className='badge-number my-2'>
                                        <span className='fs-6 text-light'>5</span>
                                    </div>
                                    <h5 class="card-title">Escalable</h5>
                                    <p class="card-text">Posibilidad de inclusión de nuevas funcionalidades.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Fin capacidades */}


            {/* Metodología */}

            <div>
                <TitleSectionBadge title="Metodología" description="Nuestras metodologías siguen estrictos estándares de calidad apegados a certificaciones, lo cual nos hace capaces de afrontar cualquier reto con la seguridad y confianza."></TitleSectionBadge>
                <div className="container-devops">
                    <div className="devops-bg"></div>
                </div>

                <div className="container">
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2'>
                        <div className="col my-2">
                            <div class="card-no-shadow">
                                <div class="card-body">

                                    <div className='badge-number my-2'>
                                        <span className='fs-6 text-light'>01</span>
                                    </div>
                                    <h5 class="card-title">Testing</h5>
                                    <p class="card-text">Pruebas, pruebas y más pruebas. Entendemos que los sistemas podrían tener vulnerabilidades por ello sometemos nuestros sistemas a pruebas de testing.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-2">
                            <div class="card-no-shadow">
                                <div class="card-body">
                                    <div className='badge-number my-2'>
                                        <span className='fs-6 text-light'>02</span>
                                    </div>
                                    <h5 class="card-title">Devops Culture</h5>
                                    <p class="card-text">Desarrollalab amplía su ecosistema de DevOps para centrarse en la innovación. Agilice la colaboración en equipo y aumente la productividad del mismo con una solución completa y fácil de utilizar.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-2">
                            <div class="card-no-shadow">
                                <div class="card-body">
                                    <div className='badge-number my-2'>
                                        <span className='fs-6 text-light'>03</span>
                                    </div>
                                    <h5 class="card-title">SCRUM</h5>
                                    <p class="card-text">En Desarrollalab hacemos uso de SCRUM desde que comenzamos a desarrollar y lo aplicamos en todos los proyectos que realizamos, así que tu proyecto o producto siempre estará en buenas manos ya que siempre estarás acompañado de expertos en el área.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Fin metodología */}



            <div class="d-flex justify-content-center flex-column">
                <TitleSectionBadge title="Inicia tu próximo proyecto con Desarrollalab" description="Desarrollamos una cartera de servicios que ofrecen una solución a tu proyecto en 360 grados."></TitleSectionBadge>
                <a href="/cotiza" class="btn btn-more-info">Contáctanos ➤➤</a>
            </div>

            <div className='container-text-techbehemots'>
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">
                        <div className="col">
                            <TitleSectionBadge title="Empresa Verificada" important="Best of the Best" description="| Empresas de Software"></TitleSectionBadge>
                        </div>
                        <div className="col">
                            <div className='techbehemoths-container'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
