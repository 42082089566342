import React, { useEffect, useState } from 'react'
import '../assets/Index.css'
import Hero from '../components/Hero'
import HeroMobile from '../components/HeroMobile'
import WaFloatButton from '../components/WaFloatButton'
import TitleSectionBadge from '../components/TitleSectionBadge'
import GridMockups from '../components/GridMockups'
import Testimonials from '../components/Testimonials'
import Services from '../components/Services'
import CallMessage from '../components/CallMessage'
import Clients from '../components/Clients'
import Footer from '../components/Footer'
import MenuNavegacionMobile from '../components/MenuNavegacionMobile'
import WorldGlobe from '../components/Globe/Globe'
import ReviewsGoogle from '../components/ReviewsGoogle'
import Mackups from '../components/Mockups'

export default function Inicio() {


    const options = {
        speed: 0.01,
        width: 800,
        height: 600
    }



    return (
        <div>
            <div id='pc'>
                <Hero
                    showButton={true} to={"/cotiza"}
                    important="Expertos"
                    description={"Crear valor duradero en toda la empresa con la innovación tecnológica"}
                    name="en construir Soluciones de software a medida"
                    classHeroPage="heroPage">
                </Hero>
            </div>

            <div id="mb">
                <HeroMobile
                    showButton={true} to={"/cotiza"}
                    important="Expertos en construir Soluciones de software a medida"
                    description={"Crear valor duradero en toda la empresa con la innovación tecnológica"}
                    classHeroPage="heroPage"
                >

                </HeroMobile>
            </div>

            <Clients></Clients>


            <TitleSectionBadge title="¿Qué hacemos?" important="Somos especialistas," description="en todos estos servicios y muchos más"></TitleSectionBadge>
            <Services></Services>


            <div className="my-5">
                <h1 className="text-dark title-brands">Proyectos <span className='text-purple-mexican'>destacados</span></h1>
                <div className='text-center'>
                    <span className='fs-4 text-dark'>Proyectos que superan las expectativas de nuestros clientes</span>
                </div>
            </div>


            <Mackups limit={4} />


            <div className="hight-space"></div>



            <div className="hight-space"></div>

            <ReviewsGoogle />



            <div className="container-world-dots">
                <TitleSectionBadge titleLight={true} title="Empresa de nivel internacional" important="Soluciones de nivel mundial"></TitleSectionBadge>
                <div className="world-dots"></div>
            </div>


            <div class="container d-flex justify-content-center flex-column my-5">

                <h1 className="text-dark title-brands">Inicia tu proyecto con <span className='text-purple-mexican'>Desarrollalab</span></h1>
                <div className='text-center my-3'>
                    <span class="caption-text-purple"> <span class="caption-text text-dark my-auto">Cotiza tu proyecto ahora, desarrollamos </span>
                        proyectos éxitosos para empresas de todo el mundo.
                    </span>
                    <div className='my-4'>
                        <a href="/cotiza" class="btn btn-more-info">Contáctanos ➤➤</a>
                    </div>
                </div>

            </div>
            <div className="hight-space"></div>

            <div className='container-fluid container-text-techbehemots'>
                <div className="container my-5">
                    <div className="row d-flex align-items-center">
                        <h1 className="text-light title-brands">Premiaciones <span className='text-purple-mexican'>destacadas</span></h1>
                        <div className='text-center'>
                            <span class="text-light fs-5">Top Software Company in México</span>
                        </div>
                    </div>
                    <div className="row row-cols-3">
                        <div className="col-12 my-3 col-md-4 d-flex justify-content-center">
                            <a href="https://techbehemoths.com/awards-2022/mexico" target={"_blank"} rel={"noopener"}>
                                <div className='award-custom-software'></div>
                            </a>
                        </div>
                        <div className="col-12 my-3  col-md-4 d-flex justify-content-center">
                            <a href="https://techbehemoths.com/awards-2022/mexico" target={"_blank"} rel={"noopener"}>
                                <div className='award-web-development'></div>
                            </a>
                        </div>
                        <div className="col-12 my-3 col-md-4 d-flex justify-content-center">
                            <a href="https://techbehemoths.com/awards-2022/mexico" target={"_blank"} rel={"noopener"}>
                                <div className='award-uxui'></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
