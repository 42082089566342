import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function PlanCard(props) {

    const [showExample, setShowExample] = useState(false)


    const handleShowExample = () => {
        setShowExample(!showExample)
    }

    const returnPlanAddons = () => {
        switch (props.planName) {

            case "Básico":
                return (
                    <ul className="list-group list-group-flush my-2">
                        <li className='li-plan'>• Dominio Gratis .com</li>
                        <li className='li-plan'>• Hosting gratis por 12 meses</li>
                        <li className='li-plan'>• Diseño profesional</li>
                        <li className='li-plan'>• Certificado SSL</li>
                        <li className='li-plan'>• Diseño web adaptable a los dispositivos</li>
                        <li className='li-plan'>• 1 sección</li>
                        <li className='li-plan'>• Galería con 7 imágenes y descripción</li>
                        <li className='li-plan'>• Datos de contacto en el pie de página</li>
                        <li className='li-plan'>• Mapa de ubicación de Google con botón de redirección a la navegación</li>
                    </ul>

                )

            case "Prémium":
                return (
                    <ul className="list-group list-group-flush my-2">
                        <li className='li-plan'>• Dominio Gratis .com, .com.mx</li>
                        <li className='li-plan'>• Hosting gratis por 12 meses</li>
                        <li className='li-plan'>• Diseño profesional</li>
                        <li className='li-plan'>• Certificado SSL</li>
                        <li className='li-plan'>• Diseño web adaptable a los dispositivos</li>
                        <li className='li-plan'>• 3 secciones</li>
                        <li className='li-plan'>• Galería con 15 imágenes y descripción</li>
                        <li className='li-plan'>• Datos de contacto en el pie de página</li>
                        <li className='li-plan'>• Formulario de Contacto</li>
                        <li className='li-plan'>• Integración con redes sociales</li>
                    </ul>
                )

            case "Empresarial":
                return (
                    <ul className="list-group list-group-flush my-2">
                        <li className='li-plan'>• Dominio Gratis .com, .com.mx o .mx</li>
                        <li className='li-plan'>• Hosting gratis por 12 meses</li>
                        <li className='li-plan'>• Diseño corporativo</li>
                        <li className='li-plan'>• Certificado SSL</li>
                        <li className='li-plan'>• Redacción corporativa</li>
                        <li className='li-plan'>• Diseño web adaptable a los dispositivos</li>
                        <li className='li-plan'>• 7 secciones</li>
                        <li className='li-plan'>• Galería con 30 imágenes y descripción</li>
                        <li className='li-plan'>• Datos de contacto en el pie de página</li>
                        <li className='li-plan'>• SEO Especializado y Optimizado</li>
                        <li className='li-plan'>• Formulario de Contacto</li>
                        <li className='li-plan'>• Integración con redes sociales</li>
                        <li className='li-plan'>• Integración con WhatsApp Business</li>
                        <li className='li-plan'>• Mapa de ubicación de Google con botón de redirección a la navegación</li>
                    </ul>
                )
            default:
                break;
        }
    }


    const returnExamplePage = () => {
        switch (props.planName) {

            case "Básico":
                return (
                    <>
                        <div className="d-flex justify-content-center">
                            <div style={{ width: '300px', height: '300px', direction: 'rtl' }}>
                                <iframe
                                    src="https://delvalleconstructionllc.com/"
                                    className='shadow rounded-2xl'
                                    style={{
                                        transform: 'scale(0.28, 0.28) translate(1340px, -940px)',
                                        width: '1050px',
                                        height: '850px',
                                        borderRadius: '50px',
                                    }}
                                ></iframe>
                            </div>
                        </div>

                        <div className="my-2 d-flex justify-content-center">
                            <button onClick={handleShowExample} className='btn bg-transparent small btn-hide-example'>Ocultar</button>
                        </div>
                    </>
                )

            case "Prémium":
                return (

                    <>
                        <div className="d-flex justify-content-center">
                            <div style={{ width: '300px', height: '300px', direction: 'rtl' }}>
                                <iframe
                                    src="https://www.pacificboatsd.com/"
                                    className='shadow rounded-2xl'
                                    style={{
                                        transform: 'scale(0.28, 0.28) translate(1340px, -940px)',
                                        width: '1050px',
                                        height: '850px',
                                        borderRadius: '50px',
                                    }}
                                ></iframe>
                            </div>
                        </div>

                        <div className="my-2 d-flex justify-content-center">
                            <button onClick={handleShowExample} className='btn bg-transparent small btn-hide-example'>Ocultar</button>
                        </div>
                    </>

                )

            case "Empresarial":
                return (
                    <>
                        <div className="d-flex justify-content-center">
                            <div style={{ width: '300px', height: '300px', direction: 'rtl' }}>
                                <iframe
                                    src="https://pro-welding.com.mx/"
                                    className='shadow rounded-2xl'
                                    style={{
                                        transform: 'scale(0.28, 0.28) translate(1340px, -940px)',
                                        width: '1050px',
                                        height: '850px',
                                        borderRadius: '50px',
                                    }}
                                ></iframe>
                            </div>
                        </div>

                        <div className="my-2 d-flex justify-content-center">
                            <button onClick={handleShowExample} className='btn bg-transparent small btn-hide-example'>Ocultar</button>
                        </div>
                    </>
                )
            default:
                break;
        }
    }

    const returnPlanEcommerceAddons = () => {
        switch (props.planName) {

            case "Básico":
                return (
                    <ul className="list-group list-group-flush my-2">
                        <li className='li-plan'>• Dominio Gratis .com</li>
                        <li className='li-plan'>• Hospedaje en Google Cloud por 12 meses</li>
                        <li className='li-plan'>• Diseño profesional</li>
                        <li className='li-plan'>• Certificado SSL</li>
                        <li className='li-plan'>• Diseño web adaptable a los dispositivos</li>
                        <li className='li-plan'>• 1 sección</li>
                        <li className='li-plan'>• Carrito de compras</li>
                        <li className='li-plan'>• Datos de contacto en el pie de página</li>
                        <li className='li-plan'>• Pasarela de pagos</li>
                        <li className='li-plan'>• Inventario</li>
                        <li className='li-plan'>• Panel de administración</li>
                    </ul>

                )

            case "Prémium":
                return (
                    <ul className="list-group list-group-flush my-2">
                        <li className='li-plan'>• Dominio Gratis .com, .com.mx</li>
                        <li className='li-plan'>• Hospedaje en Google Cloud por 12 meses</li>
                        <li className='li-plan'>• Diseño profesional</li>
                        <li className='li-plan'>• Certificado SSL</li>
                        <li className='li-plan'>• Diseño web adaptable a los dispositivos</li>
                        <li className='li-plan'>• 3 secciones</li>
                        <li className='li-plan'>• Datos de contacto en el pie de página</li>
                        <li className='li-plan'>• Formulario de Contacto</li>
                        <li className='li-plan'>• Integración con redes sociales</li>
                        <li className='li-plan'>• Carrito de compras</li>
                        <li className='li-plan'>• Pasarela de pagos</li>
                        <li className='li-plan'>• Inventario</li>
                        <li className='li-plan'>• Panel de administración</li>
                    </ul>
                )

            case "Empresarial":
                return (
                    <ul className="list-group list-group-flush my-2">
                        <li className='li-plan'>• SEO Especializado y Optimizado</li>
                        <li className='li-plan'>• Carrito de compras</li>
                        <li className='li-plan'>• Pasarela de pagos</li>
                        <li className='li-plan'>• Inventario</li>
                        <li className='li-plan'>• Panel de administración</li>
                        <li className='li-plan'>• Pagos con PayPal</li>
                        <li className='li-plan'>• Pagos con Mercado Pago</li>
                        <li className='li-plan'>• Pagos con Google Pay y Apple Pay</li>
                        <li className='li-plan'>• Integración con plataformas de envios</li>
                        <li className='li-plan'>• Integración con WhatsApp Business</li>
                        <li className='li-plan'>• Dominio Gratis .com, .com.mx o .mx</li>
                        <li className='li-plan'>• Hospedaje en Google Cloud por 12 meses</li>
                        <li className='li-plan'>• 7 secciones</li>
                        <li className='li-plan'>• Formulario de Contacto</li>
                        <li className='li-plan'>• Integración con redes sociales</li>
                        <li className='li-plan'>• Diseño corporativo</li>
                        <li className='li-plan'>• Certificado SSL</li>
                        <li className='li-plan'>• Redacción corporativa</li>
                        <li className='li-plan'>• Diseño web adaptable a los dispositivos</li>
                        <li className='li-plan'>• Datos de contacto en el pie de página</li>
                    </ul>
                )
            default:
                break;
        }
    }

    return (
        <div className="container-plan-card">
            <div className="container-namePlan">
                <span className='plan-name'>{props.planName}</span>
            </div>
            <div className="d-flex justify-content-center p-2 my-2">
                <span className='work-days'>{props.workDays}</span>
            </div>
            {/* 
            {
                props.discount ?
                    <div className='container-plan-price my-2'>
                        <span className='text-gray-discount'>MXN${props.planPrice}</span>
                    </div>
                    :
                    ""
            } */}
            <div className="container-plan-price my-2">
                <span className='plan-price'>MXN${props.planPrice}</span>
            </div>
            <div className="d-flex justify-content-center my-4">
                <a href='https://api.whatsapp.com/send?phone=+527772590365&text=Hola Desarrollalab, quiero cotizar mi proyecto:' className="btn btn-solicitar-info py-2">Me interesa ➤➤</a>
            </div>

            {

                props.planType == "web" ?
                    returnPlanAddons()
                    :
                    returnPlanEcommerceAddons()
            }

            <button onClick={handleShowExample} className="btn btn-solicitar-info py-2">Ver ejemplo</button>
            {
                showExample ?
                    returnExamplePage(props.planName)
                    :
                    null
            }
        </div>
    )
}
