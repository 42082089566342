import React from 'react'
import { Link } from 'react-router-dom'
import '../../../assets/Index.css'
import ClutchReview from '../../ClutchReview'
export default function Footer() {
    return (
        <footer class="p-4 ">
            <div class="container">
                <div class="row row-cols-sm-1 row-cols-md-3">
                    <div class="col line-footer-col">
                        <div class="logo_desarrollalab_footer"></div>
                        <h5 class="caption-text-purple fs-5 text-start">Sobre Desarrollalab</h5>
                        <div>
                            <p className='text-light fs-6 my-2'>
                                Desarrollalab es un empresa de Desarrollo de Software, fundada en la
                                ciudad de Cuernavaca, Morelos, México.
                            </p>
                        </div>

                        <div className="d-flex">
                            <div className="row row-cols-md-2 gap-4">
                                <a href="https://techbehemoths.com/company/desarrollalab-soluciones-tecnologicas" target={"_blank"} class="techBehemots"></a>
                                <a href="https://www.goodfirms.co/directory/languages/top-software-development-companies" target={"_blank"} class="goodFirms"></a>
                            </div>
                        </div>
                        <div className="center-content">
                            <a href="https://clutch.co/profile/desarrollalab" target={"_blank"} class="clutchCo"></a>
                        </div>
                        {/* <div>
                            <a href="/" className="text-white text-start fs-6">• Nosotros</a>
                        </div>
                        <div>
                            <a href="/" className="text-white text-start fs-6">• Blog</a>
                        </div>
                        <div>
                            <a href="/" className="text-white text-start fs-6">• Contacto</a>
                        </div>
                        <div>
                            <a href="/" className="text-white text-start fs-6">• Valores</a>
                        </div> */}
                    </div>
                    <div class="col line-footer-col">

                        <h5 class="caption-text-purple fs-5 text-start">Nuestros Servicios</h5>
                        <div>
                            <Link to={"/paginas-web"} className="text-white text-start fs-6">• Desarrollo de Páginas web</Link>
                        </div>
                        <div>
                            <Link to={"/ecommerce"} className="text-white text-start fs-6">• E-commerce</Link>
                        </div>
                        <div>
                            <Link to={"/desarrollo-web"} className="text-white text-start fs-6">• Desarrollo Web</Link>
                        </div>
                        <div>
                            <Link to={"/aplicaciones-moviles"} className="text-white text-start fs-6">• Desarrollo de Apps Móviles</Link>
                        </div>

                        <div>
                            <Link to={"/desarrollo-de-software"} className="text-white text-start fs-6">• Software a medida</Link>
                        </div>
                    </div>
                    <div class="col line-footer-col">
                        <div className="my-2">
                            <h5 class="caption-text-purple fs-5 text-start">Contacto</h5>
                            <div>
                                <a href='tel:+527772590365' className='text-light fs-6 my-2'>
                                    Tel: +527772590365
                                </a>
                            </div>
                            <div>
                                <a href='mailto:proyectos@desarrollalab.com' className='text-light fs-6 my-2'>
                                    E-mail: proyectos@desarrollalab.com
                                </a>
                            </div>
                        </div>

                        <div className="my-2">
                            <h5 class="caption-text-purple fs-5 text-start">Legales</h5>
                            <div>
                                <a href="#" class="text-white text-start fs-6">• Términos y condiciones</a>
                            </div>
                            <div>
                                <a href="#" class="text-white text-start fs-6">• Politica de privacidad</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}