import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import '../assets/Index.css'
import { useEffect } from 'react';
export default function FormularioContacto() {

    const sendMessage = (e) => {
        e.preventDefault();
        if (state.name !== undefined && state.email !== undefined) {
            if (a + b == result) {
                setIsLoading(true)
                e.preventDefault();
                emailjs.send('service_gmail', 'template_1w1qa9f', state, 'v2aYf4x3Dbbtd-BWj')
                    .then((response) => {
                        alert("Recibimos tu mensaje, te contactaremos a la brevedad")
                        setIsLoading(false)
                        window.location.reload()
                    }, (err) => {
                        console.log('FAILED...', err);
                    });

            } else {
                console.log(a, b, result)
                alert("La suma no es correcta")
            }
        } else {
            alert("Por favor rellena todos los campos")
        }
    }

    const [state, setState] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeText = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const [a, setA] = useState(0)
    const [b, setB] = useState(0)

    const [result, setResult] = useState(0)


    const handleSuma = (value) => {
        setResult(Number(value))
    }


    useEffect(() => {
        setA(Math.floor(Math.random() * 20) + 1)
        setB(Math.floor(Math.random() * 10) + 1)
    }, [])

    return (
        <article>
            <div className="c-form">
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">
                        <div className="col my-3">
                            <div className="container-form-description">
                                <div>
                                    <p className='m-2 purple-gradient-text fs-2 fw-bold'>Con tecnología de ingeniería avanzada damos solución a los proyectos más demandantes</p>
                                </div>
                            </div>


                        </div>
                        <div className="col">
                            <div className="container-form p-2 bg-transparent">
                                <form className='form my-4'>
                                    <div className="d-flex my-2">
                                        <input onChange={(e) => handleChangeText("name", e.target.value)} type="text" className="transparent-border-input form-control my-1" placeholder="Nombre completo"></input>
                                    </div>
                                    <div className="d-flex w-100 my-2">
                                        <input onChange={(e) => handleChangeText("email", e.target.value)} type="email" className="transparent-border-input m-1" placeholder="Correo electrónico"></input>
                                        <input onChange={(e) => handleChangeText("phone", e.target.value)} type="text" className="transparent-border-input m-1" placeholder="Teléfono"></input>
                                    </div>
                                    <div className="my-2 d-flex justify-content-center">
                                        <input onChange={(e) => handleChangeText("company", e.target.value)} type="text" className="transparent-border-input m-1" placeholder="Empresa u Organización"></input>
                                    </div>

                                    <div className="my-2 justify-content-center">
                                        <label htmlFor="service" className='small-label'>Elige un servicio <label className='text-danger'>*</label></label>
                                        <select className='form-select form-select-rounded-transparent m-1' id="service" onChange={(e) => handleChangeText("servicio", e.target.value)}>
                                            <option value="" selected>Selecciona un servicio</option>
                                            <option value="Desarrollo Web">Desarrollo Web</option>
                                            <option value="E-commerce">E-commerce</option>
                                            <option value="Desarrollo de Software">Desarrollo de Software</option>
                                            <option value="Aplicaciones Móviles">Aplicaciones Móviles</option>
                                        </select>
                                    </div>
                                    <div className="my-2 justify-content-center">
                                        <label htmlFor="projectType" className='small-label'>Tipo de proyecto <label className='text-danger'>*</label></label>
                                        <select className='form-select form-select-rounded-transparent m-1' id="projectType" onChange={(e) => handleChangeText("tipoProyecto", e.target.value)}>
                                            <option value="" selected>Selecciona tipo de proyecto</option>
                                            <option value="Startup">Startup</option>
                                            <option value="Empresarial">Empresarial</option>
                                            <option value="Corporativo">Corporativo</option>
                                            <option value="Escalable">Escalable</option>
                                        </select>
                                    </div>
                                    <div className="my-2 justify-content-center">
                                        <label htmlFor="projectStage" className='small-label'>Etapa del proyecto <label className='text-danger'>*</label></label>
                                        <select className='form-select form-select-rounded-transparent m-1' id="projectStage" onChange={(e) => handleChangeText("etapaProyecto", e.target.value)}>
                                            <option value="" selected>Selecciona etapa del proyecto</option>
                                            <option value="Existente">Existente</option>
                                            <option value="Nuevo">Nuevo</option>
                                        </select>
                                    </div>
                                    <div className="my-2 justify-content-center">
                                        <label htmlFor="projectStage" className='small-label'>¿Cuándo deseas iniciar tu proyecto? <label className='text-danger'>*</label></label>
                                        <select id="projectStage" className='form-select form-select-rounded-transparent m-1' onChange={(e) => handleChangeText("inicioProyecto", e.target.value)}>
                                            <option value="" selected>Selecciona inicio del proyecto</option>
                                            <option value="En meses">En meses</option>
                                            <option value="En semanas">En semanas</option>
                                            <option value="De inmediato">De inmediato</option>
                                        </select>
                                    </div>
                                    <div className="my-3 d-flex justify-content-center">
                                        <input onChange={(e) => handleSuma(e.target.value)} type="text" className="w-100 transparent-border-input" placeholder={`¿Cuánto es?: ${a} + ${b}`}></input>
                                    </div>
                                    <div className='my-2 d-flex justify-content-center'>
                                        {
                                            isLoading == false ?
                                                <button onClick={(e) => sendMessage(e)} className='w-100 btn btn-more-info'>Cotizar ahora</button>
                                                :
                                                <button className="w-100 btn btn-more-info" disabled>
                                                    <span className="spinner-border text-light" role="status" aria-hidden="true"></span>
                                                </button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </article>
    )
}
