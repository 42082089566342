import React from 'react';
import '../assets/Index.css';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const ProjectItem = ({ project }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    return (
        <div
            ref={ref}
            className={`position-relative contenedor ${inView ? 'fade-in-up' : ''}`}
        >
            <div className="nombre top-left-text text-start mx-4">
                <h4 className='proyecto-numero'>{project.number}</h4>
                <h4 className='proyecto-nombre' style={{ color: project.color }}>{project.name}</h4>
                <h4 className='proyecto-plan'>{project.plan}</h4>
            </div>
            <img className='prototype mx-auto' alt="Mockup" src={project.imageUrl} />
            <div className="bottom-center-text position-absolute text-center w-100">
                <a href={project.url} target='_blank' className='text-sm'>{project.url.replace(/^https?:\/\//, '')}</a>
            </div>
        </div>
    );
};

const Mackups = ({ limit }) => {


    const projects = [
        {
            number: 'PROYECTO 098',
            name: 'FE ABOGADOS',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-feabogados.png?alt=media&token=257d5cb7-49dd-4929-b3e6-3c807871b9f7`,
            url: 'https://ea-abogados.com/',
            color: '#1C5780'
        },
        {
            number: 'PROYECTO 167',
            name: 'CDMXACCESORIOS',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-cdmxaccesorios.png?alt=media&token=d974c80a-f14d-464d-a018-bdbfac8bb330`,
            url: 'https://www.cdmxaccesorios.com',
            color: '#1F5676'
        },

        {
            number: 'PROYECTO 180',
            name: 'CASA EN PAGOS',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-casaenpagos.png?alt=media&token=975d8c3a-0059-45ae-b2a8-8b3057cc26ce`,
            url: 'https://casaenpagos.com',
            color: '#C959A0'
        },
        {
            number: 'PROYECTO 144',
            name: 'VIPARLARE',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-viparlare.png?alt=media&token=a855299e-85ae-43c9-a15f-b3f2e121b84b`,
            url: 'https://viparlare.mx',
            color: '#335FA8'
        },
        {
            number: 'PROYECTO 125',
            name: 'CAUSA Y EFECTO',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-causayefecto.png?alt=media&token=8d266aac-6096-4ff6-b2fa-43b49aac8130`,
            url: 'https://causayefecto.mx',
            color: '#2BC3F2'
        },
        {
            number: 'PROYECTO 056',
            name: 'OA ARQUITECTOS',
            plan: 'PLAN / EMPRESARIAL',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-oaarquitectos.png?alt=media&token=18e035c2-7ff8-40ca-a0ca-368ff7481260`,
            url: 'https://oaarquitectos.com',
            color: '#717171'
        },
        {
            number: 'PROYECTO 172',
            name: 'IA TRAVEL',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-iatravel.png?alt=media&token=1740dba8-024b-4bb8-b8c5-d3b888c919bf`,
            url: 'https://iatravelmx.com',
            color: '#2270F8'
        },
        {
            number: 'PROYECTO 213',
            name: 'FINCA LA ESPERANZA',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-fincalaesperanza.png?alt=media&token=f7f5cf5a-c99a-4263-8285-443c800592e1`,
            url: 'http://fincalaesperanza.mx',
            color: '#DABB7D'
        },
        {
            number: 'PROYECTO 110',
            name: 'ABSOLUTE TRADER',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-absolutetrader.png?alt=media&token=43390b7e-2df5-4b6c-aa22-6f11f2f39266`,
            url: 'https://absolutetrader.vercel.app',
            color: '#593C2A'
        },
        {
            number: 'PROYECTO 092',
            name: 'DEL VALLE CONSTRUCTION LLC',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-delvalle.png?alt=media&token=46e02267-d776-407c-beda-3d98a13b8e24`,
            url: 'https://delvalleconstructionllc.vercel.app',
            color: '#FC6E12'
        },
        {
            number: 'PROYECTO 205',
            name: 'COIL INSTRUMENTACION',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-coilinstru.png?alt=media&token=4b405873-dfc1-4e68-830b-b49abc099157`,
            url: 'https://coilinstrumentacion.com',
            color: '#CF1E1E'
        },
        {
            number: 'PROYECTO 251',
            name: 'UNIFORMES UNILOMAS',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-uniformesunilomas.png?alt=media&token=5634d1fa-2311-4b81-8c38-565a19ee57ce`,
            url: 'https://uniformesunilomas.com',
            color: '#0D1C31'
        },
        {
            number: 'PROYECTO 221',
            name: 'FISCALBLED',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-fiscalbled.png?alt=media&token=44060fe0-398a-455a-a278-76bc81e58396`,
            url: 'https://fiscalbled.com',
            color: '#155E75'
        },
        {
            number: 'PROYECTO 214',
            name: 'CLEVEREXC',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-cleverexc.png?alt=media&token=3f616853-0b81-41c6-aba4-05ed0e5904c6`,
            url: 'https://cleverexc.vercel.app',
            color: '#E9B914'
        },
        {
            number: 'PROYECTO 089',
            name: 'CLINICMKT',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-clinickmkt.png?alt=media&token=70119135-0b4e-482a-b09a-77335409842a`,
            url: 'https://clinickmkt.com',
            color: '#2CD3DE'
        },
        {
            number: 'PROYECTO 230',
            name: 'MARKETINGLAB',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-marketinglab.png?alt=media&token=ff7be6d4-d0ae-4660-b726-02753c31c073`,
            url: 'https://marketinglab.mx',
            color: '#E8DB03'
        },
        {
            number: 'PROYECTO 201',
            name: 'MG DERMA',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-mg-derma.png?alt=media&token=4514cbc8-1837-45d9-9d4a-92ea8254d863`,
            url: 'https://mgderma.com',
            color: '#4492A2'
        },
        {
            number: 'PROYECTO 181',
            name: 'MEFIR',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-mefir.png?alt=media&token=66fb83c2-03fb-481c-9bf2-0fdb7e2016a3`,
            url: 'https://mefir.mx',
            color: '#F7931D'
        },
        {
            number: 'PROYECTO 110',
            name: 'PACIFIC AUTO DETAILING',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-pacificauto.png?alt=media&token=e0e950b4-be45-46aa-9e95-b0c4f59e64bf`,
            url: 'https://pacificautodetailing.com',
            color: '#05414E'
        },
        {
            number: 'PROYECTO 163',
            name: 'LEGADIGMA',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-legadigma.png?alt=media&token=3bacb09a-1db9-48bd-8561-97e41f94327c`,
            url: 'https://legadigma.com',
            color: '#A17C0B'
        },
        {
            number: 'PROYECTO 171',
            name: 'JUICINGSYSTEMS',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-juicingsystems.png?alt=media&token=342c3383-6458-4053-9584-cfe87a6ab912`,
            url: 'https://juicingsystems.com',
            color: '#B22828'
        },
        {
            number: 'PROYECTO 155',
            name: 'PRO-WELDING',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-prowelding.png?alt=media&token=71480879-59a1-4ff8-901d-d535ec71941d`,
            url: 'https://pro-welding.com.mx',
            color: '#593C2A'
        },
        {
            number: 'PROYECTO 169',
            name: 'UQ CONSTRUCTION',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-uqconstructionllc.png?alt=media&token=22616b32-2514-47b0-a3f2-f3e78e827895`,
            url: 'https://uqconstructionllc.com',
            color: '#6B21A8'
        },
        {
            number: 'PROYECTO 174',
            name: 'SUNSET ROOFING SERVICES LLC',
            plan: 'PLAN / PREMIUM',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-sunsetroofinaservices.png?alt=media&token=4c540624-dc45-435b-be15-8993a5b0a70f`,
            url: 'https://sunsetroofingservicesllc.com',
            color: '#F97316'
        },

    ];

    return (
        <div>
            {projects.slice(0, limit).map((project, index) => (
                <ProjectItem key={index} project={project} />
            ))}

            <div className="d-flex justify-content-center">
                <Link to={"/portafolio"} className='btn btn-more-info'>Ver más proyectos</Link>
            </div>
        </div>
    );
};

export default Mackups;
