import React from 'react'
import FadeInLeft from '../animations/FadeInLeft'
export default function CardMockup(props) {
    return (
        <div className={`container-fluid py-4`} style={{ backgroundColor: `${props.design.backgroundColor}` }}>
            <div id='pc' className="row row-cols-2">
                <div className="col-8"></div>
                <div className="col-4">

                    <div
                        style={{
                            justifyContent: 'center',
                            backgroundImage: `url(${(props.logoEmpresa)})`,
                        }}
                        className="card-mockup-logo-empresa p-4"></div>
                </div>
            </div>
            <div id="mb">

                <div className="container p-4 justify-content-center d-flex">

                    <div
                        style={{
                            justifyContent: 'center',
                            backgroundImage: `url(${(props.logoEmpresa)})`,
                        }}
                        className="card-mockup-logo-empresa"></div>
                </div>
            </div>
            <div className="row row-cols-2">


                <div className="col-12 col-md-6">
                    <div className="container my-3">
                        <span style={{ color: `${props.design.textColor}` }} className='fw-bold display-6'>{props.nombreEmpresa}</span>
                        <div>
                            <p style={{ color: `${props.design.textColor}` }} className='fw-bold fs-5'>{props.actividadEmpresa}</p>
                        </div>
                        <p style={{ color: `${props.design.textColor}` }} className='small'>{props.descripcionProyecto}</p>
                    </div>
                </div>

                <div className="col-12 col-md-6 p-0">
                    <div
                        style={{
                            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.0)),url(${(props.images[0])})`,
                            backgroundSize: 'cover',
                        }}>

                        <div className='container bg-glass'
                        >

                            <div className="table-responsive hideScrollbar" >

                                <table>
                                    <tr>
                                        {
                                            props.images.map((image, index) => {
                                                return (
                                                    <td>
                                                        <img key={index} src={image} className="mockup m-2" />
                                                    </td>
                                                )
                                            }
                                            )
                                        }
                                    </tr>
                                </table>
                                <span id='mb' style={{ color: `${props.design.textColor}` }} className='fs-6'>Desliza para ver más ➤➤</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
