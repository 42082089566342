import React, { useEffect } from 'react'
import '../assets/Index.css'
import HeroMobile from '../components/HeroMobile'
import WaFloatButton from '../components/WaFloatButton'
import TitleSectionBadge from '../components/TitleSectionBadge'
import Mockups from '../components/Mockups'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'


const Hero = () => {
    return (
        <div className="hero-container">
            <div className="hero-content container">
                <div className="row">
                    <div className="col-12 col-md-6 title-hero-portfolio">
                        <div className="titulo">
                            <h1>Proyectos de <br /><span className="">Calidad <span className='highlight'>Mundial</span></span></h1>
                            <button className="hero-button btn btn-outline-light">Agendar una reunión</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                        </div>

                        <div className="d-flex flex-row-reverse">
                            <div className="hero-subtext col-md-6 text-center">
                                Creación de una experiencia de realidad virtual para que los clientes puedan visualizar un nuevo producto antes de su lanzamiento.
                            </div>
                        </div>

                        <img className='linea-hero' src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/desarrollalab%2Flinea-hero.svg?alt=media&token=74cdc03f-9a05-44c5-a415-93c80551d7f8" alt="Línea decorativa" />

                        <div className="hero-footer col-md-5 text-center">
                            <span className='font-light'>Amamos lo que hacemos y lo demostramos en cada proyecto</span>
                        </div>
                        <div className="col-md-7">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default function Portafolio() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>

            <Hero />

            <Spacer></Spacer>

            <section className="portfolio-section text-center py-5">
                <h2 className="mb-3">SOLUCIONES WEB EMPRESARIALES:</h2>
                <h3 className="text-danger mb-4">NUESTRO PORTAFOLIO DE PROYECTOS EXITOSOS</h3>
                <div className="d-flex justify-content-center">
                    <a href='/paginas-web' className="btn btn-light mx-2">Ver Planes</a>
                    <a href='/cotiza' className="btn btn-light mx-2">Contacto</a>
                </div>
            </section>
            <Mockups limit={100} />


            <div class="d-flex justify-content-center flex-column">
                <TitleSectionBadge title="Inicia tu próximo proyecto con Desarrollalab" description="Desarrollamos una cartera de servicios que ofrecen una solución a tu proyecto en 360 grados."></TitleSectionBadge>
                <a href="/cotiza" class="btn btn-more-info">Contáctanos ➤➤</a>
            </div>

            <div className='container-fluid container-text-techbehemots'>
                <div className="container my-5">
                    <div className="row d-flex align-items-center">
                        <h1 className="text-light title-brands">Premiaciones <span className='text-purple-mexican'>destacadas</span></h1>
                        <div className='text-center'>
                            <span class="text-light fs-5">Top Software Company in México</span>
                        </div>
                    </div>
                    <div className="row row-cols-3">
                        <div className="col-12 my-3 col-md-4 d-flex justify-content-center">
                            <div className='award-custom-software'></div>
                        </div>
                        <div className="col-12 my-3  col-md-4 d-flex justify-content-center">
                            <div className='award-web-development'></div>
                        </div>
                        <div className="col-12 my-3 col-md-4 d-flex justify-content-center">
                            <div className='award-uxui'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
