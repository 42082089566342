import React from 'react'

export default function TitleSectionBadge(props) {
    return (
        <>
            {
                props.titleLight == true ?

                    <div class="d-flex justify-content-center flex-column p-2 my-4">
                        <h1 className='text-light title-brands my-4'>{props.title}</h1>

                        <span class="caption-text text-light my-auto"> <span class=" text-light caption-text-purple">{props.important} </span>
                            {props.description}
                        </span>
                    </div>
                    :
                    <div class="d-flex justify-content-center flex-column p-2 my-4">
                        <h1 className='text-dark title-brands my-4'>{props.title}</h1>

                        <span class="caption-text my-auto"> <span class="caption-text-purple">{props.important} </span>
                            {props.description}
                        </span>
                    </div>
            }
        </>


    )
}
