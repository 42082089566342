import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../../assets/Index.css'
import logo_desarrollalab from '../../../assets/images/047_Logo-desarrollalab-redesigned-black.png'

export default function Header(props) {

    // Crea un estado para controlar el estado del menú
    const [menuOpen, setMenuOpen] = useState(false);

    // Define una función para cambiar el estado del menú
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const returnCurrentActiveLink = () => {
        switch (props.activeLink) {

            case "inicio":
                return (

                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to={"/"} className="nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to={"/cotiza"} className="nav-link active">Cotizar</Link></li>
                        <li className="nav-item"><Link to={"/agendar-reunion"} className="nav-link">Agendar reunión</Link></li>
                        <li className="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><Link to={"/paginas-web"} className="nav-link dropdown-item">Desarrollo de Páginas web</Link></li>
                                    <li><Link to={"/ecommerce"} className="nav-link dropdown-item">E-commerce</Link></li>
                                    <li><Link to={"/desarrollo-web"} className="nav-link dropdown-item">Desarrollo Web</Link></li>

                                    <li><Link to={"/aplicaciones-moviles"} className="nav-link dropdown-item">Desarrollo de Apps Móviles</Link></li>
                                    <li><Link to={"/desarrollo-de-software"} className="nav-link dropdown-item">Software a medida</Link></li>

                                </ul>
                            </div>
                        </li>
                        <li className="nav-item"><Link to={"/portafolio"} className="nav-link">Portafolio</Link></li>
                    </ul>

                )

            case "cotiza":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to={"/"} className="nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to={"/cotiza"} className="nav-link active">Cotizar</Link></li>
                        <li className="nav-item"><Link to={"/agendar-reunion"} className="nav-link">Agendar reunión</Link></li>
                        <li className="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><Link to={"/paginas-web"} className="nav-link dropdown-item">Desarrollo de Páginas web</Link></li>
                                    <li><Link to={"/ecommerce"} className="nav-link dropdown-item">E-commerce</Link></li>
                                    <li><Link to={"/desarrollo-web"} className="nav-link dropdown-item">Desarrollo Web</Link></li>

                                    <li><Link to={"/aplicaciones-moviles"} className="nav-link dropdown-item">Desarrollo de Apps Móviles</Link></li>
                                    <li><Link to={"/desarrollo-de-software"} className="nav-link dropdown-item">Software a medida</Link></li>

                                </ul>
                            </div>
                        </li>
                        <li className="nav-item"><Link to={"/portafolio"} className="nav-link">Portafolio</Link></li>
                    </ul>
                )

            case "portafolio":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to={"/"} className="nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to={"/cotiza"} className="nav-link">Cotizar</Link></li>
                        <li className="nav-item"><Link to={"/agendar-reunion"} className="nav-link">Agendar reunión</Link></li>
                        <li className="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><Link to={"/paginas-web"} className="nav-link dropdown-item">Desarrollo de Páginas web</Link></li>
                                    <li><Link to={"/ecommerce"} className="nav-link dropdown-item">E-commerce</Link></li>
                                    <li><Link to={"/desarrollo-web"} className="nav-link dropdown-item">Desarrollo Web</Link></li>

                                    <li><Link to={"/aplicaciones-moviles"} className="nav-link dropdown-item">Desarrollo de Apps Móviles</Link></li>
                                    <li><Link to={"/desarrollo-de-software"} className="nav-link dropdown-item">Software a medida</Link></li>

                                </ul>
                            </div>
                        </li>
                        <li className="nav-item"><Link to={"/portafolio"} className="nav-link active">Portafolio</Link></li>
                    </ul>
                )
            case "servicios":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to={"/"} className="nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to={"/cotiza"} className="nav-link">Cotizar</Link></li>
                        <li className="nav-item"><Link to={"/agendar-reunion"} className="nav-link">Agendar reunión</Link></li>
                        <li className="nav-item">
                            <div class="dropdown">
                                <a class="nav-link active dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><Link to={"/paginas-web"} className="nav-link dropdown-item">Desarrollo de Páginas web</Link></li>
                                    <li><Link to={"/ecommerce"} className="nav-link dropdown-item">E-commerce</Link></li>
                                    <li><Link to={"/desarrollo-web"} className="nav-link dropdown-item">Desarrollo Web</Link></li>

                                    <li><Link to={"/aplicaciones-moviles"} className="nav-link dropdown-item">Desarrollo de Apps Móviles</Link></li>
                                    <li><Link to={"/desarrollo-de-software"} className="nav-link dropdown-item">Software a medida</Link></li>

                                </ul>
                            </div>
                        </li>
                        <li className="nav-item"><Link to={"/portafolio"} className="nav-link">Portafolio</Link></li>
                    </ul>
                )
            case "apps-web":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to={"/"} className="nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to={"/cotiza"} className="nav-link">Cotizar</Link></li>
                        <li className="nav-item"><Link to={"/agendar-reunion"} className="nav-link">Agendar reunión</Link></li>
                        <li className="nav-item">
                            <div class="dropdown">
                                <a class="nav-link active dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><Link to={"/paginas-web"} className="nav-link dropdown-item">Desarrollo de Páginas web</Link></li>
                                    <li><Link to={"/ecommerce"} className="nav-link dropdown-item">E-commerce</Link></li>
                                    <li><Link to={"/desarrollo-web"} className="nav-link dropdown-item">Desarrollo Web</Link></li>

                                    <li><Link to={"/aplicaciones-moviles"} className="nav-link dropdown-item">Desarrollo de Apps Móviles</Link></li>
                                    <li><Link to={"/desarrollo-de-software"} className="nav-link dropdown-item">Software a medida</Link></li>

                                </ul>
                            </div>
                        </li>
                        <li className="nav-item"><Link to={"/portafolio"} className="nav-link">Portafolio</Link></li>
                    </ul>
                )
            case "agendar-reunion":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to={"/"} className="nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to={"/cotiza"} className="nav-link">Cotizar</Link></li>
                        <li className="nav-item"><Link to={"/agendar-reunion"} className="nav-link active">Agendar reunión</Link></li>
                        <li className="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><Link to={"/paginas-web"} className="nav-link dropdown-item">Desarrollo de Páginas web</Link></li>
                                    <li><Link to={"/ecommerce"} className="nav-link dropdown-item">E-commerce</Link></li>
                                    <li><Link to={"/desarrollo-web"} className="nav-link dropdown-item">Desarrollo Web</Link></li>

                                    <li><Link to={"/aplicaciones-moviles"} className="nav-link dropdown-item">Desarrollo de Apps Móviles</Link></li>
                                    <li><Link to={"/desarrollo-de-software"} className="nav-link dropdown-item">Software a medida</Link></li>

                                </ul>
                            </div>
                        </li>
                        <li className="nav-item"><Link to={"/portafolio"} className="nav-link">Portafolio</Link></li>
                    </ul>
                )

            default:
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to={"/"} className="nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to={"/cotiza"} className="nav-link active">Cotizar</Link></li>
                        <li className="nav-item"><Link to={"/cotiza"} className="nav-link">Agendar reunión</Link></li>
                        <li className="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><Link to={"/paginas-web"} className="nav-link dropdown-item">Desarrollo de Páginas web</Link></li>
                                    <li><Link to={"/ecommerce"} className="nav-link dropdown-item">E-commerce</Link></li>
                                    <li><Link to={"/desarrollo-web"} className="nav-link dropdown-item">Desarrollo Web</Link></li>

                                    <li><Link to={"/aplicaciones-moviles"} className="nav-link dropdown-item">Desarrollo de Apps Móviles</Link></li>
                                    <li><Link to={"/desarrollo-de-software"} className="nav-link dropdown-item">Software a medida</Link></li>

                                </ul>
                            </div>
                        </li>
                        <li className="nav-item"><Link to={"/portafolio"} className="nav-link">Portafolio</Link></li>
                    </ul>
                )
                break;
        }
    }
    return (
        <>
            <header id='pc' className="d-flex flex-wrap justify-content-center py-3  border-bottom">
                <title>{props.title}</title>
                <a href="/" className="d-flex align-items-center mx-3 mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <img className='' src={logo_desarrollalab} height="70" alt=""></img>
                </a>

                <ul className="nav nav-pills d-flex align-items-center">
                    {
                        returnCurrentActiveLink()
                    }
                </ul>
            </header>


            <header id='mb'>

                <title>{props.title}</title>
                <nav class="navbar navbar-light bg-light container">
                    <div className="container d-flex">
                        <div className="col-10 p-1 d-flex">
                            <div className="nav-container d-flex w-100 flex-row justify-content-center">
                                <div onClick={() => window.location.href = "/"} className="logo_container_nav_color"></div>
                            </div>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <button className="navbar-toggler" onClick={toggleMenu}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                    </div>
                </nav>





                {
                    menuOpen == true ?

                        <div class="bg-light p-4">
                            <div className="items">
                                <div className='my-4 underline_red'>
                                    <Link to={"/"}>Inicio</Link>
                                </div>
                                <div className='my-4 underline_red'>
                                    <Link to={"/cotiza"}>Cotizar</Link>
                                </div>
                                <div className='my-4 underline_red'>
                                    <Link to={"/agendar-reunion"}>Agenda una reunión</Link>
                                </div>
                                <div className='my-4 underline_red'>
                                    <Link to={"/portafolio"}>Portafolio</Link>
                                </div>
                                <div class="dropdown">
                                    <a class="underline_red dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        Servicios
                                    </a>

                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li><Link to={"/paginas-web"} className="nav-link dropdown-item">Desarrollo de Páginas web</Link></li>
                                        <li><Link to={"/ecommerce"} className="nav-link dropdown-item">E-commerce</Link></li>
                                        <li><Link to={"/aplicaciones-moviles"} className="nav-link dropdown-item">Desarrollo de Apps Móviles</Link></li>
                                        <li><Link to={"/desarrollo-web"} className="nav-link dropdown-item">Desarrollo Web</Link></li>
                                        <li><Link to={"/desarrollo-de-software"} className="nav-link dropdown-item">Software a medida</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </header>
        </>
    )
}