import React from 'react'
import TitleSectionBadge from '../components/TitleSectionBadge'
import Slider from "react-slick"
export default function Clients() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 1,
        cssEase: "linear"
    };
    return (
        <div className='container hight-space'>

            <div className="col title-col my-4">
                <h1 className="text-dark title-brands">Con la <span className='text-purple-mexican'>confianza</span> de grandes empresas</h1>
            </div>
            <div className="hight-space"></div>
            <Slider {...settings}>
                <div className="m-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Fbrand_002_ignite.png?alt=media&token=ab27fd1b-5415-468b-a997-4bc81ea92b23"
                        alt=""></img>
                </div>
                <div className="my-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Fbrand_004_mitsubichi.svg?alt=media&token=51eea798-522b-4ab4-b32c-6e60224778c5"
                        alt=""></img>
                </div>
                <div className="my-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2FOA_ARQUITECTOS_LOGOTIPO_BLANCO.jpg?alt=media&token=93098ce7-c1c8-4339-beed-0592f92b52fa"
                        alt=""></img>
                </div>
                <div className="my-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Fbrand_003_clpa.png?alt=media&token=084331ae-2ccd-4d1d-88a5-3731cb6a2e31"
                        alt=""></img>
                </div>
                <div className="my-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2F001-logo-draedithlealcastaneda-resize.png?alt=media&token=f706b682-82a1-4df8-8bc4-6b0a24c5db21"
                        alt=""></img>
                </div>
                <div className="my-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Fbrand_004_mitsubichi.svg?alt=media&token=51eea798-522b-4ab4-b32c-6e60224778c5"
                        alt=""></img>
                </div>
                <div className="my-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Flogo-reprovet1.png?alt=media&token=bd1dd99e-0e0a-45e5-8c84-3a879261ad6d"
                        alt=""></img>
                </div>
                <div className="my-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Flogo_fos_resized.png?alt=media&token=4f32d295-1866-472b-8ca3-0d35c6b35dfc"
                        alt=""></img>
                </div>
                <div className="my-2">
                    <img className="brand-logo" src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Flogo_idisraelita.png?alt=media&token=64d84238-3012-4208-bd0b-3910547d9a15"
                        alt=""></img>
                </div>
            </Slider>
        </div>
    )
}
