import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/Index.css'

export default function Hero(props) {
    return (
        <div className="p-4" id={props.classHeroPage}>
            <div className="container-content">
                <h1 className={`heroTitle title-brands-hero my-2 ${props.classTextColor} ${props.classText}`}>{props.important}</h1>
                <h1 className="heroTitle my-2">{props.name}</h1>
                <div className="my-4">
                    <span className={`text-white text-start fs-5 ${props.classTextColor}`}>{props.description}</span>
                </div>
                {
                    props.showButton == true ?
                        <Link className="btn btn-more-info my-4" style={{ borderRadius: '10px' }} to={props.to}>Conoce más ➤➤</Link>
                        :
                        ""
                }

                {
                    window.location.pathname !== '/agendar-reunion' ?
                        <Link className="btn btn-more-info m-2" style={{ borderRadius: '10px' }} to={"/agendar-reunion"}>Agendar una reunión ➤➤</Link>
                        :
                        null
                }
            </div>

        </div>
    )
}
