import React from 'react'
import { Link } from 'react-router-dom'

export default function Hero({ title, description, leftImage, buttonText, buttonLinkTo }) {
    return (

        <div>
            <div className="container-fluid bg-dark-blue">
                <div className="container">

                    <div className="row row-cols-2">
                        <div className="col-12 col-md-7 d-flex align-items-center">
                            <div className="container">
                                <div className="my-4">
                                    <h1 className='fs-1 text-left text-light fw-bold'>{title}</h1>
                                </div>
                                <div className="my-4">
                                    <p className='fs-4 text-light'>{description}</p>
                                </div>
                                {
                                    buttonText ?
                                        <div className="my-4">
                                            <Link to={buttonLinkTo} className="btn btn-more-info">{buttonText}</Link>
                                        </div>
                                        :
                                        null
                               }
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="container">
                                <img src={leftImage} className="left-image-hero" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
