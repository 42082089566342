import React, { useEffect } from 'react'
import '../assets/Index.css'
import Hero from '../components/Hero'
import HeroMobile from '../components/HeroMobile'
import FormularioCitas from '../components/FormularioCitas'
import Spacer from '../components/Spacer'


export default function AgendarCita() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{ backgroundColor: "#F2F2FA" }}>
            <div id="pc">
                <Hero
                    heroImage={true}
                    classText={"text-light"}
                    important="Agenda una reunión"
                    description={'¡Nos encanta escuchar tus ideas! Agenda una llamada o reunión virtual para cotizar tu proyecto y obtén una respuesta inmedaita'}
                    classHeroPage="heroAgendarCita" classHeroImage="heroPortafolio"></Hero>
            </div>

            <div id="mb">

                <HeroMobile
                    important="Agenda una reunión"
                    classText={"text-light"}
                    classTextColor="text-light fw-bold"
                    description={'¡Nos encanta escuchar tus ideas! Agenda una llamada o reunión virtual para cotizar tu proyecto y obtén una respuesta inmedaita'}
                    classHeroPage="heroAgendarCita"></HeroMobile>
            </div>

            <Spacer></Spacer>


            <div class="container d-flex justify-content-center flex-column">
                <h1 className="text-dark title-brands">Estás a un paso de <span className='text-purple-mexican'>comenzar algo extraordinario</span></h1>
                <div className='text-center'>
                    <span class="text-dark fs-6">Rellena el formulario a continuación y te contactaremos a la brevedad, estamos disponibles para ti</span>
                </div>
            </div>


            <div className="container bg-transparent">
                <div className="row row-cols-2">
                    <div className="col-12 col-md-6">
                        <img className='book-consulting-img-service' src="https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Hero-image-software-development-v5.webp?alt=media&token=f14263ee-dc9c-48ad-b496-be9a892a3c45" />
                    </div>
                    <div className="col-12 col-md-6">
                        <FormularioCitas />
                    </div>

                </div>
            </div>

            <div className='container-fluid container-text-techbehemots'>
                <div className="container my-5">
                    <div className="row d-flex align-items-center">
                        <h1 className="text-light title-brands">Premiaciones <span className='text-purple-mexican'>destacadas</span></h1>
                        <div className='text-center'>
                            <span class="text-light fs-5">Top Software Company in México</span>
                        </div>
                    </div>
                    <div className="row row-cols-3">
                        <div className="col-12 my-3 col-md-4 d-flex justify-content-center">
                            <div className='award-custom-software'></div>
                        </div>
                        <div className="col-12 my-3  col-md-4 d-flex justify-content-center">
                            <div className='award-web-development'></div>
                        </div>
                        <div className="col-12 my-3 col-md-4 d-flex justify-content-center">
                            <div className='award-uxui'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
