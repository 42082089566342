import React from 'react'
import '../assets/Index.css'
import Footer from '../components/Footer'
export default function NotFound() {
    return (
        <>
            <div className="container-fluid">

                <div className="d-flex justify-content-center my-4">
                    <div className="not-found-image"></div>
                </div>
                <div className="text-center my-3">
                    <h1 className='text-dark fw-bold'>Página no encontrada | 404</h1>
                    <a href='/' class="btn btn-more-info my-2">Página de inicio ➤➤</a>
                </div>
            </div>
        </>
    )
}
