import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


// Public Views
import Inicio from "./pages/Inicio";
import Contacto from "./pages/Contacto";
import Portafolio from "./pages/Portafolio";
import NotFound from "./pages/NotFound";
import PaginasWeb from "./pages/PaginasWeb";
import AppsWeb from "./pages/AppsWeb";
import Ecommerce from "./pages/Ecommerce";
import Software from "./pages/Software";
import AppsMoviles from "./pages/AppsMoviles";
import Layout from "./components/layout/Layout";
import DesarrolloWeb from "./pages/DesarrolloWeb";

import SeguridadWeb from "./pages/SeguridadWeb";
import ConfirmSubscription from "./pages/ConfirmSubscription";
import AgendarCita from "./pages/AgendarCita";


function App() {
  render(
    <Router>
      <Routes>
        <Route index element={
          <Layout
            title={"Inicio | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"inicio"}>
            <Inicio />
          </Layout>
        }></Route>
        <Route path="/cotiza" element={
          <Layout
            title={"Cotiza | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"cotiza"}>
            <Contacto />
          </Layout>
        }></Route>
        <Route path="/desarrollo-de-software" element={
          <Layout title={"Desarrollo de Software | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"desarrollo-de-software"}>
            <Software />
          </Layout>
        }></Route>

        <Route path="/aplicaciones-moviles" element={
          <Layout title={"Desarrollo de Aplicaciones Móviles | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"aplicaciones-moviles"}>
            <AppsMoviles />
          </Layout>
        }></Route>

        <Route path="/portafolio" element={
          <Layout title={"Portafolio de Proyectos | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"portafolio"}>
            <Portafolio />
          </Layout>
        }></Route>

        <Route path="/paginas-web" element={
          <Layout title={"Diseño y Desarrollo de Páginas Web Profesionales | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"paginas-web"}
          >
            <PaginasWeb />
          </Layout>
        }></Route>

        <Route path="/aplicaciones-web" element={
          <Layout title={"Desarrollo de Aplicaciones Web | Desarrollalab - Soluciones Tecnológicas"}>
            <AppsWeb />
          </Layout>
        }></Route>

        <Route path="/ecommerce" element={
          <Layout title={"Creación de Tiendas en Línea | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"ecommerce"}>
            <Ecommerce />
          </Layout>
        }></Route>
        <Route path="/desarrollo-web" element={
          <Layout title={"Diseño y Desarrollo Web | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"desarrollo-web"}>
            <DesarrolloWeb />
          </Layout>
        }></Route>
        <Route path="/agendar-reunion" element={
          <Layout title={"Agenda tu cita y cotiza tu proyecto | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"agendar-reunion"}>
            <AgendarCita />
          </Layout>
        }></Route>
        {/* <Route path="/seguridad-web" element={
          <Layout title={"Seguridad Web | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"seguridad-web"}>
            <SeguridadWeb />
          </Layout>
        }></Route> */}

        <Route path="*" element={
          <Layout title={"Página no encontrada | Desarrollalab - Soluciones Tecnológicas"}
            activeLink={"404"}>
            <NotFound />
          </Layout>
        }></Route>
        {/* <Route path="/confirmSubscription/ede7a6c87c5318a599089354d2621f0696a50/:idPaypal/:idDoc" element={
          <ConfirmSubscription />
        }></Route> */}
      </Routes>
    </Router>,
    document.getElementById("root")
  );
}

export default App;