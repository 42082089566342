import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDRCdsY1Tin2t-h61tJ5QdvyVTHFVRYO6s",
    authDomain: "desarrollalab-erp.firebaseapp.com",
    projectId: "desarrollalab-erp",
    storageBucket: "desarrollalab-erp.appspot.com",
    messagingSenderId: "751765222857",
    appId: "1:751765222857:web:91bb93faf8a61914956418"
};

const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();
export const fieldValue = firebase.firestore.FieldValue;
export const auth = app.auth();
export const storage = app.storage();
export const functions = app.functions()